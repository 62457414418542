import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountRoutingModule } from './account-routing.module';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { StudentComponent } from './component/dashboard/student/student.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MainSkillComponent } from './component/dashboard/main-skill/main-skill.component';
import { SubSkillComponent } from './component/dashboard/sub-skill/sub-skill.component';
import { CommentComponent } from './component/dashboard/comment/comment.component';
import { ReflectiveComponent } from './component/dashboard/reflective/reflective.component';
import { SessionComponent } from './component/dashboard/session/session.component';
import { AccountComponent } from './component/dashboard/account/account.component';
import { GraphComponent } from './component/dashboard/graph/graph.component';
import {ChartsModule} from 'ng2-charts';
import {StudentSkillComponent} from './component/student-skill/student-skill.component';
import {NgbTooltipModule, NgbProgressbarModule, NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import { DownloadComponent } from './component/dashboard/download/download.component';
import {TranslateSharedModule} from "../translate-shared.module";

@NgModule({
  declarations: [
    DashboardComponent,
    StudentComponent,
    MainSkillComponent,
    SubSkillComponent,
    CommentComponent,
    ReflectiveComponent,
    SessionComponent,
    AccountComponent,
    GraphComponent,
    StudentSkillComponent,
    DownloadComponent],
  imports: [
    CommonModule,
    AccountRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    NgbTooltipModule,
    NgbProgressbarModule, NgbModalModule,
    TranslateSharedModule
  ],
  exports: [DashboardComponent]
})
export class AccountModule { }
