<ng-progress id="progressBar"></ng-progress>
<ngx-alerts></ngx-alerts>
<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" *ngIf="!authService.loggedIn()" [routerLink]="['/']">Get Autism Active</a>
  <a class="navbar-brand" *ngIf="authService.loggedIn()" [routerLink]="['/account']">Get Autism Active</a>
  <button class="navbar-toggler"
          type="button"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          [attr.aria-expanded]="!isCollapsed"
          aria-label="Toggle navigation" (click)="isCollapsed = !isCollapsed">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNav" [ngbCollapse]="!isCollapsed">
    <ul class="navbar-nav">
      <li class="nav-item active" *ngIf="!authService.loggedIn()">
        <a class="nav-link" [routerLink]="['/']" [routerLinkActive]="'active'"
           (click)="isCollapsed = !isCollapsed">{{'menu.home.label' | translate}}</a>
      </li>
      <li class="nav-item" *ngIf="!authService.loggedIn()">
        <a class="nav-link" [routerLink]="['/register']" [routerLinkActive]="'active'" ngbTooltip="{{'menu.sign_up.tooltip' | translate}}"
           (click)="isCollapsed = !isCollapsed">{{'menu.sign_up.label' | translate}}</a>
      </li>
      <!--<li class="nav-item" *ngIf="!authService.loggedIn()">-->
      <!--<a class="nav-link" [routerLink]="['/login']" [routerLinkActive]="'active'" ngbTooltip="Sign In">Sign In</a>-->
      <!--</li>-->
      <li class="nav-item" *ngIf="authService.loggedIn()">
        <a class="nav-link" [routerLink]="['/account']" [routerLinkActive]="'active'"
           ngbTooltip="{{'menu.my_student.tooltip' | translate}}"
           (click)="isCollapsed = !isCollapsed">{{'menu.my_student.label' | translate}}</a>
      </li>
      <li class="nav-item" *ngIf="authService.loggedIn()">
        <a class="nav-link" [routerLink]="['/account/edit']" [routerLinkActive]="'active'"
           ngbTooltip="{{'menu.account.tooltip' | translate}}"
           (click)="isCollapsed = !isCollapsed">{{'menu.account.label' | translate}}</a>
      </li>

      <li class="nav-item">
        <a class="nav-link" href="#" ngbTooltip="{{'menu.faq.tooltip' | translate}}">{{'menu.faq.label' | translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#" ngbTooltip="{{'menu.contact.tooltip' | translate}}">{{'menu.contact.label' | translate}}</a>
      </li>
      <li class="nav-item" *ngIf="authService.loggedIn()" style="cursor: pointer;">
        <a class="nav-link" (click)="logout()" ngbTooltip="{{'menu.logout.tooltip' | translate}}">{{'menu.logout.label' |
          translate}}</a>
      </li>
    </ul>
  </div>
  <div class="text-right">
    <a (click)="useLanguage('en')" style="cursor: pointer;text-decoration: underline;">English</a> | <a
    (click)="useLanguage('ie')" style="cursor: pointer;text-decoration: underline;">Gaeilge</a>
  </div>
</nav>
