import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService} from '../../servises/account.service';
import {ProgressBarService} from '../../../shared/services/progress-bar.service';
import {AlertService} from 'ngx-alerts';

@Component({
  selector: 'app-student-skill',
  templateUrl: './student-skill.component.html',
  styleUrls: ['./student-skill.component.css']
})
export class StudentSkillComponent implements OnInit {
  student = null;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private accountService: AccountService,
              public progressBar: ProgressBarService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      this.loadStudent(params.id);
    });
  }

  loadStudent(studentId): void {
    this.progressBar.startLoading();
    const observer = {
      next: res => {
        this.progressBar.completeLoading();
        this.student = res.data;
      },
      error: err => {
        this.progressBar.completeLoading();
        this.alertService.danger(err.error.error);
        this.router.navigate(['/account']);
      }
    };
    this.accountService.studentDetail(studentId).subscribe(observer);
  }

}
