// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: 'https://api.digilogue1.com/',
  GRANT_TYPE: 'password',
  CLIENT_ID: '92a0c47f-fdb3-4d2a-bd41-7f03fea79a99',
  CLIENT_SECRET: 'bYvidaeZkJ09NEr26P1keStCo1ucf2sYihHUx8GP',
  SCOPE: '',
  DEFAULT_LANGUAGE: 'en',
  SUPPORTED_LANGUAGES: ['en', 'ie']
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
