import {Component, OnInit, ChangeDetectionStrategy, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService} from '../../../servises/account.service';
import {ProgressBarService} from '../../../../shared/services/progress-bar.service';
import jsPDF from 'jspdf';
import {TranslateService, LangChangeEvent} from "@ngx-translate/core";

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraphComponent implements OnInit,AfterViewInit {
  studentId = null;
  student = null;
  locale = '';

  constructor(private route: ActivatedRoute,
              private router: Router,
              private accountService: AccountService,
              public progressBar: ProgressBarService,
              private translateService: TranslateService) {
  }

  @ViewChild('myCanvas')
  myCanvas: ElementRef<HTMLCanvasElement>;

  public context: CanvasRenderingContext2D;

  ngAfterViewInit(): void {
    this.context = this.myCanvas.nativeElement.getContext('2d');
  }

  public chartColors: Array<any> = [
    { // first color
      backgroundColor: 'rgba(135,129,129,0.5)',
      borderColor: 'rgba(5,5,5,0.2)',
      pointBackgroundColor: 'rgba(14,14,14,0.2)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(225,10,24,0.2)'
    }];

  public radarChartLabels = [];
  public radarChartData = [
    {data: [], label: 'Skills', responsive: true},
  ];
  public radarChartType = 'radar';

  ngOnInit(): void {
    this.route.parent.params.subscribe((params: any) => {
      if (this.studentId != params.id) {
        this.studentId = params.id;
        this.list();
        this.translateService.get('main_skill.heading').subscribe((event: string) =>{
          this.radarChartData = [
            {data: [], label: event, responsive: true},
          ];
        })
      }
    });
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if (this.locale != event.lang) {
        this.locale = event.lang;
         this.radarChartData = [
           {data: [], label: event.translations.main_skill.heading, responsive: true},
         ];
        this.list();
      }
    });
  }

  list(): void {
    this.progressBar.startLoading();
    const observer = {
      next: res => {
        this.progressBar.completeLoading();
        console.log(res);
        this.radarChartLabels = res.data.chart.labels;
        this.radarChartData[0].data = res.data.chart.values;
        this.student = res.data.student;
      },
      error: err => {
        this.progressBar.completeLoading();
        console.log(err);
      }
    };
    this.accountService.getSpiderGraph(this.studentId).subscribe(observer);
  }

  download() {
    const graphImage = this.context.canvas.toDataURL('image/png', 1.0);
    const doc: any = new jsPDF('p', 'pt', 'a4');
    let xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(this.student.name) * doc.internal.getFontSize() / 2);
    doc.text(this.student.name, xOffset, 20);

    doc.addImage(graphImage, 'JPEG', 5, 20, 600, 450);
    // Save the PDF
    doc.save(this.student.name.replace(' ', '-') + '-' + Date.now().toString() + '.pdf');
  }

}
