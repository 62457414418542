import {Component, OnInit} from '@angular/core';
import {NgProgress, NgProgressRef} from 'ngx-progressbar';
import {ProgressBarService} from '../../services/progress-bar.service';
import {AlertService} from 'ngx-alerts';
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from '../../services/auth.service';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from "@angular/common";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  isCollapsed = false;
  locale = "";

  constructor(private activatedRoute: ActivatedRoute,
              private progress: NgProgress,
              public progressRef: ProgressBarService,
              public authService: AuthService,
              private router: Router,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.progressRef.progressRef = this.progress.ref('progressBar');
    this.setDefaultLanguage();
  }

  logout(): void {
    this.progressRef.startLoading();
    const loginObserver = {
      next: x => {
        this.progressRef.completeLoading();
        console.log('login success');
      },
      error: err => {
        this.progressRef.completeLoading();
        console.log(err);
        // this.alertService.danger('Email and/or Password is incorrect');
      }
    };
    this.authService.logout().subscribe(loginObserver);
    this.isCollapsed = false;
    this.router.navigate(['/']);
  }

  useLanguage(language: string) {
    localStorage.setItem('lang', language);
    this.translateService.use(language);
  }

  private setDefaultLanguage(): void {
    const lang = localStorage.getItem('lang');
    if (lang == null) {
      localStorage.setItem('lang', environment.DEFAULT_LANGUAGE);
      this.translateService.use(environment.DEFAULT_LANGUAGE);
    } else {
      this.translateService.use(localStorage.getItem('lang'));
    }
  }

}
