import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DashboardComponent} from './component/dashboard/dashboard.component';
import {GuardService} from '../shared/services/guard.service';
import {MainSkillComponent} from './component/dashboard/main-skill/main-skill.component';
import {SubSkillComponent} from './component/dashboard/sub-skill/sub-skill.component';
import {SessionComponent} from './component/dashboard/session/session.component';
import {AccountComponent} from './component/dashboard/account/account.component';
import {GraphComponent} from './component/dashboard/graph/graph.component';
import {StudentSkillComponent} from './component/student-skill/student-skill.component';
import {NotFoundComponentComponent} from '../shared/components/not-found-component/not-found-component.component';
import {DownloadComponent} from "./component/dashboard/download/download.component";

const routes: Routes = [
  {path: 'account', component: DashboardComponent, canActivate: [GuardService]},
  {path: 'account/edit', component: AccountComponent, canActivate: [GuardService]},
  {path: 'student/:id', component: StudentSkillComponent, children: [
      {path: 'skill', component: MainSkillComponent, canActivate: [GuardService]},
      {path: 'graph', component: GraphComponent, canActivate: [GuardService]},
      {path: 'sub-skill/:mainSkillId', component: SubSkillComponent, canActivate: [GuardService]},
      {path: 'session/:subSkillId', component: SessionComponent, canActivate: [GuardService]},
      {path: 'download', component: DownloadComponent, canActivate: [GuardService]},
    ]},
  { path: '404', component: NotFoundComponentComponent },
  { path: '**', component: NotFoundComponentComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule {
}
