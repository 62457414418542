<div class="row">
  <div class="col-md-6 offset-md-3 mt-4">
    <h3>{{'register.heading' | translate}}</h3>
    <form [formGroup]="signupForm" (ngSubmit)="register()">
      <div class="form-group">
        <label for="fill_name">{{'register.input.name.label' | translate}}</label>
        <input type="text"
               class="form-control"
               id="fill_name"
               placeholder="{{'register.input.name.placeholder' | translate}}"
               formControlName="name">
        <div *ngIf="signupForm.get('name').invalid && (signupForm.get('name').dirty || signupForm.get('name').touched)"
             class="text-danger">

          <div *ngIf="signupForm.get('name').errors.required">
            {{'register.input.name.error.required' | translate}}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="email">{{'register.input.email.label' | translate}}</label>
        <input type="email"
               class="form-control"
               id="email"
               placeholder="{{'register.input.email.placeholder' | translate}}"
               formControlName="email">
        <div
          *ngIf="signupForm.get('email').invalid && (signupForm.get('email').dirty || signupForm.get('email').touched)"
          class="text-danger">

          <div *ngIf="signupForm.get('email').errors.required">
            {{'register.input.email.error.required' | translate}}
          </div>
          <div *ngIf="signupForm.get('email').errors.email">
            {{'register.input.email.error.email' | translate}}
          </div>

        </div>
        <div
          *ngIf="errors && (signupForm.get('email').dirty || signupForm.get('email').touched)"
          class="text-danger">

          <div *ngIf="errors.email">
            {{errors.email[0]}}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="password">{{'register.input.password.label' | translate}}</label>
        <input type="password"
               class="form-control"
               id="password"
               placeholder="Password"
               formControlName="password">
        <div
          *ngIf="signupForm.get('password').invalid && (signupForm.get('password').dirty || signupForm.get('password').touched)"
          class="text-danger">

          <div *ngIf="signupForm.get('password').errors.required">
            {{'register.input.password.error.required' | translate}}
          </div>
        </div>
        <div
          *ngIf="errors && (signupForm.get('email').dirty || signupForm.get('email').touched)"
          class="text-danger">

          <div *ngIf="errors.password">
            {{errors.password[0]}}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="password">{{'register.input.confirm_password.label' | translate}}</label>
        <input type="password"
               class="form-control"
               id="password_confirmation"
               placeholder="{{'register.input.confirm_password.placeholder' | translate}}"
               formControlName="password_confirmation">
        <div
          *ngIf="signupForm.get('password_confirmation').invalid && (signupForm.get('password_confirmation').dirty || signupForm.get('password_confirmation').touched)"
          class="text-danger">
          <div *ngIf="signupForm.get('password_confirmation').errors.required">{{'register.input.confirm_password.error.required' | translate}}</div>
          <div *ngIf="signupForm.get('password_confirmation').errors.mustMatch">{{'register.input.confirm_password.error.match' | translate}}</div>
        </div>
      </div>
      <button type="submit" class="btn btn-primary" [disabled]="!signupForm.valid">{{'register.action.submit.label' | translate}}</button>
    </form>
  </div>
</div>

