<div class="row mt-3">
  <div class="col-md-12">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/account']">{{'main_skill.breadcrumb.my_student' | translate}}</a></li>
        <li class="breadcrumb-item active" aria-current="page">{{'main_skill.breadcrumb.skills' | translate}}</li>
      </ol>
    </nav>
    <div class="card">
      <div class="card-header">
        <h2>{{'main_skill.heading' | translate}}</h2>
      </div>
      <div class="card-body">
        <form [formGroup]="skillStatusForm">
          <table class="table table-sm table-striped">
            <thead>
            <tr>
              <th scope="col">{{'main_skill.col_heading.name' | translate}}</th>
              <th scope="col"></th>
              <th scope="col" style="display: none"></th>
            </tr>
            </thead>
            <tbody formArrayName="mainSkills">
            <tr *ngFor="let group of mainSkillArray.controls; let i = index;" [formGroupName]="i">
              <td style="width: 50%">{{group.value.name}}</td>
              <td style="width: 10%">
                <a [routerLink]="['/student/' + this.studentId + '/sub-skill/' +  group.value.id]"
                   class="btn btn-sm btn-success" ngbTooltip="{{'main_skill.action.sub_skill.tooltip' | translate}}">
                  <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-list-check" fill="currentColor"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                          d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
                  </svg>
                </a>
              </td>
              <td style="width: 10%; display: none"><input type="checkbox"
                                            formControlName="completed"
                                            [checked]="group.value.completed" (change)="markAsComplete(group.value)"
                                            class="form-control form-control-sm"></td>
              <td style="width: 30%">
                <p><ngb-progressbar type="info" textType="white" [value]="group.value.progress" [showValue]="true"></ngb-progressbar></p>
              </td>
            </tr>
            </tbody>
          </table>
        </form>
      </div>
    </div>
  </div>
</div>
