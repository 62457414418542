import {Injectable} from '@angular/core';
import {AuthService} from '../../shared/services/auth.service';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Router, ActivatedRoute} from '@angular/router';
import {AlertService} from 'ngx-alerts';
import {log} from "util";
import {TranslateService, LangChangeEvent} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  lang = localStorage.getItem('lang');

  constructor(private authService: AuthService,
              private http: HttpClient,
              private route: Router,
              private alertService: AlertService,
              private activatedRoute: ActivatedRoute,
              private translateService: TranslateService) {

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lang = event.lang;
    });

  }

  studentSave(model): Observable<any> {
    const headers = this.authService.getAuthenticationHeader();
    return this.http.post(environment.API_URL + `api/${this.lang}/student/create`, model, {headers});
  }

  studentList(): Observable<any> {

    const headers = this.authService.getAuthenticationHeader();
    return this.http.get(environment.API_URL + `api/${this.lang}/student/list`, {headers});
  }

  mainSkillList(studentId): Observable<any> {
    const headers = this.authService.getAuthenticationHeader();
    return this.http.get(environment.API_URL + `api/${this.lang}/skill/list/` + studentId, {headers});
  }

  subSkillList(mainSkillId, studentId): Observable<any> {
    const headers = this.authService.getAuthenticationHeader();
    return this.http.get(environment.API_URL + `api/${this.lang}/sub_skill/list/` + mainSkillId + '/' + studentId, {headers});
  }

  studentDetail(studentId): Observable<any> {
    const headers = this.authService.getAuthenticationHeader();
    return this.http.get(environment.API_URL + `api/${this.lang}/student/retrieve/` + studentId, {headers});
  }

  saveSkillComment(model): Observable<any> {
    const headers = this.authService.getAuthenticationHeader();
    return this.http.post(environment.API_URL + `api/${this.lang}/student/skill/comment/save`, model, {headers});
  }

  getSkillComment(studentId, skillId): Observable<any> {
    const headers = this.authService.getAuthenticationHeader();
    return this.http.get(environment.API_URL + `api/${this.lang}/student/skill/comment/` + `${studentId}/` + `${skillId}`, {headers});
  }

  getSkillReflective(studentId, skillId): Observable<any> {
    const headers = this.authService.getAuthenticationHeader();
    return this.http.get(environment.API_URL + `api/${this.lang}/reflective/get/` + `${studentId}/` + `${skillId}`, {headers});
  }

  saveSkillReflective(model): Observable<any> {
    const headers = this.authService.getAuthenticationHeader();
    return this.http.post(environment.API_URL + `api/${this.lang}/reflective/save`, model, {headers});
  }

  getSessionList(studentId, subSkillId): Observable<any> {
    const headers = this.authService.getAuthenticationHeader();
    return this.http.get(environment.API_URL + `api/${this.lang}/session/get/${studentId}/${subSkillId}`, {headers});
  }

  saveSession(model): Observable<any> {
    const headers = this.authService.getAuthenticationHeader();
    return this.http.post(environment.API_URL + `api/${this.lang}/session/save`, model, {headers});
  }

  getSubSkillById(subSkillId): Observable<any> {
    const headers = this.authService.getAuthenticationHeader();
    return this.http.get(environment.API_URL + `api/${this.lang}/sub_skill/get/${subSkillId}`, {headers});
  }

  skillMarkAsComplete(model): Observable<any> {
    const headers = this.authService.getAuthenticationHeader();
    return this.http.post(environment.API_URL + `api/${this.lang}/student/skill/save`, model, {headers});
  }

  getCurrentUser(): Observable<any> {
    const headers = this.authService.getAuthenticationHeader();
    return this.http.get(environment.API_URL + `api/${this.lang}/user`, {headers});
  }

  editAccount(model): Observable<any> {
    const headers = this.authService.getAuthenticationHeader();
    return this.http.post(environment.API_URL + `api/${this.lang}/user/edit`, model, {headers});
  }

  editPassword(model): Observable<any> {
    const headers = this.authService.getAuthenticationHeader();
    return this.http.post(environment.API_URL + `api/${this.lang}/user/edit-password`, model, {headers});
  }

  getSpiderGraph(studentId): Observable<any> {
    const headers = this.authService.getAuthenticationHeader();
    return this.http.get(environment.API_URL + `api/${this.lang}/student/graph/${studentId}`, {headers});
  }

  deleteStudent(studentId): Observable<any> {
    const headers = this.authService.getAuthenticationHeader();
    return this.http.delete(environment.API_URL + `api/${this.lang}/student/${studentId}`, {headers});
  }

  subjectAccess() {
    const headers = this.authService.getAuthenticationHeader();
    return this.http.get(environment.API_URL + `api/${this.lang}/ser/subject-access`, {headers});
  }

  subjectDelete() {
    const headers = this.authService.getAuthenticationHeader();
    return this.http.delete(environment.API_URL + `api/${this.lang}/user/subject-delete`, {headers});
  }

  isLoggedIn(): boolean {
    if (!this.authService.loggedIn()) {
      this.route.navigate(['/login']);
      this.alertService.warning('The session is expired please login back.');
      return false;
    }
    return true;
  }

}
