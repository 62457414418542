import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../environments/environment";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'get-autism-active-front';

  constructor() {
    // translate.setDefaultLang('en');

  }

  ngOnInit() {
    // this.setDefaultLanguage();
  }


  // useLanguage(language: string) {
  //   localStorage.setItem('lang', language);
  //   this.translate.use(language);
  // }
  //
  // private setDefaultLanguage(): void {
  //   const lang = localStorage.getItem('lang');
  //   if (lang == null) {
  //     localStorage.setItem('lang', environment.DEFAULT_LANGUAGE);
  //     this.translate.use(environment.DEFAULT_LANGUAGE);
  //   } else {
  //     this.translate.use(localStorage.getItem('lang'));
  //   }
  // }
}
