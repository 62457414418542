import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import * as moment from 'moment';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private tokenKey = 'token';
  private tokenExpiryKey = 'token_expiry';
  lang = localStorage.getItem('lang');

  constructor(private http: HttpClient,
              private cookieService: CookieService) {
  }

  login(data): any {
    const model = {
      username: data.email,
      password: data.password,
      grant_type: environment.GRANT_TYPE,
      client_id: environment.CLIENT_ID,
      client_secret: environment.CLIENT_SECRET,
      scope: environment.SCOPE
    };
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post(environment.API_URL + 'oauth/token', model, {headers}).pipe(
      map((response: any) => {
          const user = response;
          if (!user.error) {
            if (data.keepMeLoggedIn) {
              localStorage.setItem(this.tokenKey, JSON.stringify(user));
              localStorage.setItem(this.tokenExpiryKey, moment(new Date()).add(user.expires_in, 'seconds').format());
            }
            this.cookieService.set(this.tokenKey, JSON.stringify(user));
            this.cookieService.set(this.tokenExpiryKey, moment(new Date()).add(user.expires_in, 'seconds').format());
          }
        }
      )
    );
  }

  register(model): any {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post(environment.API_URL + `api/${this.lang}/register`, model, {headers});
  }

  loggedIn(): boolean {
    if (!this.cookieTokenExist() && !this.localStorageTokenExist()) {
      return false;
    }
    if (this.validateLocalStorage()) {
      return true;
    }
    return this.validateCookieStorage();
  }

  getToken(): string {
    const user = JSON.parse(decodeURI(this.cookieService.get(this.tokenKey)));
    if (user) {
      return user.access_token;
    }
    return null;
  }

  logout(): Observable<any> {
    const accessToken = this.getToken();
    if (!accessToken) {
      return null;
    }
    const headers = new HttpHeaders({'Content-Type': 'application/json', Authorization: 'Bearer ' + this.getToken()});
    this.clearBrowserStorage();
    return this.http.get(environment.API_URL + 'api/logout', {headers});
  }

  getAuthenticationHeader(): HttpHeaders {
    return new HttpHeaders({'Content-Type': 'application/json', Authorization: 'Bearer ' + this.getToken()});
  }

  private isTokenExpired(tokenTime): boolean {
    return tokenTime < moment(new Date());
  }

  public clearBrowserStorage(): void {
    localStorage.removeItem(this.tokenKey);
    localStorage.removeItem(this.tokenExpiryKey);
    this.cookieService.delete(this.tokenKey, '/');
    this.cookieService.delete(this.tokenExpiryKey, '/');
  }

  private localStorageTokenExist(): boolean {
    return !!localStorage.getItem(this.tokenKey);
  }

  private cookieTokenExist(): boolean {
    return this.cookieService.check(this.tokenKey);
  }

  private validateLocalStorage(): boolean {
    if (this.localStorageTokenExist() && !this.isTokenExpired(moment(new Date(localStorage.getItem(this.tokenExpiryKey))))) {
      this.cookieService.set(this.tokenKey, localStorage.getItem(this.tokenKey));
      this.cookieService.set(this.tokenExpiryKey, localStorage.getItem(this.tokenExpiryKey));
      return true;
    }
    return false;
  }

  private validateCookieStorage(): boolean {
    const user = JSON.parse(decodeURI(this.cookieService.get(this.tokenKey)));
    const tokenExpiryDate = moment(new Date(this.cookieService.get(this.tokenExpiryKey)));
    if (this.isTokenExpired(tokenExpiryDate)) {
      this.clearBrowserStorage();
      return false;
    }
    return !!user;
  }
}
