import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ProgressBarService} from '../../../../shared/services/progress-bar.service';
import {AlertService} from 'ngx-alerts';
import {AccountService} from '../../../servises/account.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.css']
})
export class StudentComponent implements OnInit {
  creationForm: FormGroup;
  studentList: any[];
  selectedStudent = null;

  constructor(public progressBar: ProgressBarService,
              private alertService: AlertService,
              private accountService: AccountService,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.creationForm = new FormGroup({
      name: new FormControl(null, Validators.required)
    });
    this.list();
  }

  save(): void {
    this.progressBar.startLoading();
    const data = {
      name: this.creationForm.value.name
    };
    const observer = {
      next: res => {
        this.progressBar.completeLoading();
        this.alertService.success(res.message);
        this.list();
      },
      error: err => {
        this.progressBar.completeLoading();
        console.log(err);
      }
    };
    this.accountService.studentSave(data).subscribe(observer);
    this.creationForm.reset();
  }

  list(): void {
    this.progressBar.startLoading();
    const observer = {
      next: res => {
        this.progressBar.completeLoading();

        this.studentList = res.data;
      },
      error: err => {
        this.progressBar.completeLoading();
        console.log(err);
      }
    };
    this.accountService.studentList().subscribe(observer);
  }

  skills(id): void {
    console.log(id);
  }

  remove(studentId): void {
    this.progressBar.startLoading();
    const observer = {
      next: res => {
        this.progressBar.completeLoading();
        this.alertService.success(res.message);
        this.list()
      },
      error: err => {
        this.progressBar.completeLoading();
        console.log(err);
        this.alertService.danger(err.error.error);
      }
    };
    this.accountService.deleteStudent(studentId).subscribe(observer);
    this.modalService.dismissAll();
  }

  open(confirmation, studentId) {
    this.modalService.open(confirmation, {ariaLabelledBy: 'modal-basic-title'});
    this.selectedStudent = studentId;
  }

}
