<div class="card">
  <div class="card-header">
    {{'sub_skill.comment.heading' | translate}}
  </div>
  <div class="card-body">
    <form [formGroup]="commentForm" (ngSubmit)="save()">
      <div class="form-group">

        <textarea class="form-control" id="comment" placeholder="{{'sub_skill.comment.input.comment.placeholder' | translate}}" formControlName="comment" required></textarea>
        <div
          *ngIf="commentForm.get('comment').invalid && (commentForm.get('comment').dirty || commentForm.get('comment').touched)"
          class="text-danger">

          <div *ngIf="commentForm.get('comment').errors.required">
            {{'sub_skill.comment.input.comment.error.required' | translate}}
          </div>
        </div>

      </div>
      <button type="submit" class="btn btn-primary" [disabled]="!commentForm.valid">{{'sub_skill.comment.submit.label' | translate}}</button>
    </form>
  </div>
</div>
