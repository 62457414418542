import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService} from '../../../servises/account.service';
import {ProgressBarService} from '../../../../shared/services/progress-bar.service';
import {AlertService} from 'ngx-alerts';
import {TranslateService, LangChangeEvent} from "@ngx-translate/core";

@Component({
  selector: 'app-sub-skill',
  templateUrl: './sub-skill.component.html',
  styleUrls: ['./sub-skill.component.css']
})
export class SubSkillComponent implements OnInit {
  studentId = null;
  mainSkillId = null;
  subList = null;
  student = null;
  studentPath = null;
  mainSkill = null;
  locale = '';

  constructor(private route: ActivatedRoute,
              private router: Router,
              private accountService: AccountService,
              public progressBar: ProgressBarService,
              private alertService: AlertService,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      if (this.mainSkillId != params.mainSkillId
        || this.studentId != this.route.parent.snapshot.params.id) {
        this.studentId = this.route.parent.snapshot.params.id;
        this.mainSkillId = params.mainSkillId;
        this.list(this.mainSkillId, this.studentId);
      }
    });
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if (this.locale != event.lang) {
        this.locale = event.lang;
        this.list(this.mainSkillId, this.studentId);
      }
    });
  }

  list(mainSkillId, studentId): void {
    this.progressBar.startLoading();
    const observer = {
      next: res => {
        this.progressBar.completeLoading();
        this.subList = res.data;
        this.mainSkill = res.data.main_skill;
        this.student = res.data.student;
        this.studentPath = `/student/${this.student.id}/skill`
      },
      error: err => {
        this.progressBar.completeLoading();
        this.alertService.danger(err.error.error);
        this.router.navigate(['/account']);
      }
    };
    this.accountService.subSkillList(mainSkillId, studentId).subscribe(observer);
  }

}
