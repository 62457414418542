<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a [routerLink]="['/account']">{{'session.breadcrumb.my_student' | translate}}</a></li>
    <li class="breadcrumb-item"><a [routerLink]="[studentPath]">{{student?.name}}</a></li>
    <li class="breadcrumb-item"><a [routerLink]="[mainSkillPath]">{{mainSkill?.name}}</a></li>
    <li class="breadcrumb-item active" aria-current="page">{{subSkill?.name}}</li>
  </ol>
</nav>
<div class="card mt-3">
  <div class="card-header">
    <h2>{{subSkill?.name}} - {{'session.heading' | translate}}</h2>
    <button class="btn btn-info btn-sm" (click)="add()" [disabled]="addButtonDisableStatus">
      {{'session.add_new_session.label' | translate}}
    </button>
  </div>
  <div class="card-body">
    <form action="" [formGroup]="sessionForm">
      <div formArrayName="sessions">
        <table class="table">
          <thead>
          <tr>
            <th scope="col" style="width: 25%">{{'session.columns.session' | translate}}</th>
            <th scope="col" style="width: 50%">{{'session.columns.status' | translate}}</th>
            <th scope="col" style="width: 25%">{{'session.columns.completed_date' | translate}}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let group of sessionArray.controls; let i = index;" [formGroupName]="i">
            <th>{{'session.temp_session.text' | translate}} {{group.value.session_no}}</th>
            <td>
              <!--<input type="checkbox"-->
              <!--formControlName="skill_completed"-->
              <!--[checked]="group.value.skill_completed" (change)="save(group.value)"-->
              <!--class="form-control form-control-sm">-->
              <div class="btn-group" role="group" aria-label="Basic example" *ngIf="group.value.id">
                <button type="button" class="btn btn-secondary btn-lg" (click)="save(group.value, 2)"
                        [disabled]="group.value.skill_completed !== 2">
                  {{'session.status.not_attempted.label' | translate}}
                </button>
                <button type="button" class="btn btn-danger btn-lg" (click)="save(group.value, 0)"
                        [disabled]="group.value.skill_completed !== 0">{{'session.status.failed.label' | translate}}
                </button>
                <button type="button" class="btn btn-success btn-lg" (click)="save(group.value, 1)"
                        [disabled]="group.value.skill_completed !== 1">{{'session.status.completed.label' | translate}}
                </button>
              </div>

              <div class="btn-group" role="group" aria-label="Basic example" *ngIf="!group.value.id">
                <button type="button" class="btn btn-secondary btn-lg" (click)="save(group.value, 2)">
                  {{'session.status.not_attempted.label' | translate}}
                </button>
                <button type="button" class="btn btn-danger btn-lg" (click)="save(group.value, 0)">
                  {{'session.status.failed.label' | translate}}
                </button>
                <button type="button" class="btn btn-success btn-lg" (click)="save(group.value, 1)">
                  {{'session.status.completed.label' | translate}}
                </button>
              </div>
            </td>
            <td>{{group.value.created_at | date: 'dd/MM/yyyy'}}</td>
          </tr>
          </tbody>
        </table>

      </div>
    </form>
  </div>
</div>
