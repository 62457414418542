<div class="row mt-3">
  <div class="col-md-12">
    <h5>{{mainSkill?.name}}</h5>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/account']">{{'sub_skill.breadcrumb.my_students' | translate}}</a></li>
        <li class="breadcrumb-item"><a [routerLink]="[studentPath]">{{student?.name}}</a></li>
        <li class="breadcrumb-item active" aria-current="page">{{mainSkill?.name}}</li>
      </ol>
    </nav>
    <div class="card">
      <div class="card-header">
        {{'sub_skill.identifying_features.heading' | translate}}
      </div>
      <div class="card-body">

        <table class="table table-sm table-striped">
          <thead>
          <tr>
            <th scope="col">{{'sub_skill.identifying_features.columns.name' | translate}}</th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let skill of subList?.identifying">
            <td>{{skill.name}}</td>
            <td>
              <a [routerLink]="['/student/', this.studentId,'session', skill.id]" class="btn btn-sm btn-success"
                 ngbTooltip="{{'sub_skill.identifying_features.actions.complete_skill.tooltip' | translate}}">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-list-check" fill="currentColor"
                     xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                        d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
                </svg>
              </a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="row mt-3">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        {{'sub_skill.additional_learning_opportunities.heading' | translate}}
      </div>
      <div class="card-body">

        <table class="table table-sm table-striped">
          <thead>
          <tr>
            <th scope="col">{{'sub_skill.additional_learning_opportunities.columns.name' | translate}}</th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let skill of subList?.additional">
            <td>{{skill.name}}</td>
            <td>
              <a [routerLink]="['/student/', this.studentId,'session', skill.id]" class="btn btn-sm btn-success"
                 ngbTooltip="{{'sub_skill.additional_learning_opportunities.actions.complete_skill.tooltip' | translate}}">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-list-check" fill="currentColor"
                     xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                        d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
                </svg>
              </a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="row mt-3">
  <div class="col-md-12">
    <app-comment></app-comment>
  </div>
</div>

<div class="row mt-3">
  <div class="col-md-12">
    <app-reflective></app-reflective>
  </div>
</div>
