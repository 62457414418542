<div class="row">
  <div class="col-md-6 offset-md-3 mt-4">
    <h3>{{'forgot_password.heading' | translate}}</h3>
    <form>
      <div class="form-group">
        <label for="email">{{'forgot_password.input.email.label' | translate}}</label>
        <input type="text"
               class="form-control"
               id="email"
               placeholder="{{'forgot_password.input.email.placeholder' | translate}}" required>
      </div>
      <button type="submit" class="btn btn-primary">{{'forgot_password.action.submit.label' | translate}}</button>
    </form>
  </div>
</div>

