<div class="row mt-3">
  <div class="col-md-12">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/account']">{{'visual_overview.breadcrumb.my_students' | translate}}</a></li>
        <li class="breadcrumb-item active" aria-current="page">{{'visual_overview.heading' | translate}}</li>
      </ol>
    </nav>
    <div class="card">
      <div class="card-header">
        <h2>{{'visual_overview.heading' | translate}}</h2>
      </div>
      <div class="card-body">
        <canvas baseChart #myCanvas
                [datasets]="radarChartData"
                [labels]="radarChartLabels"
                [chartType]="radarChartType"
                [colors]="chartColors" width="500" height="400"></canvas>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <button class="btn btn-info col-md-12" (click)="download()">{{'visual_overview.action.download.label' | translate}}</button>
  </div>
</div>
