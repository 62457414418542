import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../shared/services/auth.service';
import {ProgressBarService} from '../../../shared/services/progress-bar.service';
import {AlertService} from 'ngx-alerts';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  signInForm: FormGroup;

  constructor(private authService: AuthService,
              public progressBar: ProgressBarService,
              private alertService: AlertService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.signInForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, Validators.required),
      keepMeLoggedIn: new FormControl(false)
    });
  }

  login(): void {
    this.progressBar.startLoading();
    const data = {
      username: this.signInForm.value.email,
      password: this.signInForm.value.password
    };
    console.log(this.signInForm.value);
    const loginObserver = {
      next: x => {
        this.progressBar.completeLoading();
        this.router.navigate(['/account']);
        console.log('login success');
      },
      error: err => {
        this.progressBar.completeLoading();
        console.log(err);
        this.alertService.danger('Email and/or Password is incorrect');
      }
    };
    this.authService.login(this.signInForm.value).subscribe(loginObserver);
  }

  passVisible(id): void {
    const inputSelect = document.querySelector(id);
    const inputType = inputSelect.getAttribute('type');
    let iconShow; let iconHide;
    if (inputType === 'password') {
      iconShow = document.querySelector(id + '+p+span .fa-eye-slash');
      iconHide = document.querySelector(id + '+p+span .fa-eye');
      inputSelect.setAttribute('type', 'text');
    } else {
      iconShow = document.querySelector(id + '+p+span .fa-eye');
      iconHide = document.querySelector(id + '+p+span .fa-eye-slash');
      inputSelect.setAttribute('type', 'password');
    }
    iconShow.style.display = 'block';
    iconHide.style.display = 'none';
  }

}
