<div class="row mt-3">
  <div class="col-md-12">

    <div class="card">
      <div class="card-header">
        <h2>{{'student.heading' | translate}}</h2>
      </div>
      <div class="card-body">
        <form [formGroup]="creationForm" (ngSubmit)="save()">
          <div class="input-group mb-3">
            <input type="text"
                   class="form-control"
                   placeholder="{{'student.input.new_student.placeholder' | translate}}"
                   aria-label="Type new Student's name"
                   aria-describedby="basic-addon2"
                   formControlName="name"
                   required>
            <div class="input-group-append">
              <button class="input-group-text btn-success" id="basic-addon2" [disabled]="!creationForm.valid" type="submit" style="cursor:pointer;" ngbTooltip="{{'student.input.new_student.tooltip' | translate}}">+
              </button>
            </div>
          </div>
          <div
            *ngIf="creationForm.get('name').invalid && (creationForm.get('name').dirty || creationForm.get('name').touched)"
            class="text-danger">

            <div *ngIf="creationForm.get('name').errors.required">
              {{'student.validation.name' | translate}}
            </div>

          </div>
        </form>
        <table class="table table-sm">
          <thead>
          <tr>
            <th scope="col">{{'common.name' | translate}}</th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let student of studentList">
            <td>{{student.name}}</td>
            <td>
              <a [routerLink]="['/student/'+ student.id + '/skill']" class="btn btn-lg btn-success"
                 ngbTooltip="{{'student.tooltip.record_skill' | translate}}">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-list-check" fill="currentColor"
                     xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                        d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
                </svg>
              </a>
              <a [routerLink]="['/student/'+ student.id + '/graph']" class="btn btn-lg btn-info ml-2"
                 ngbTooltip="{{'student.tooltip.visual_overview' | translate}}">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-bar-chart-line" fill="currentColor"
                     xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                        d="M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2zm1 12h2V2h-2v12zm-3 0V7H7v7h2zm-5 0v-3H2v3h2z"/>
                </svg>
              </a>
              <button class="btn btn-lg btn-danger  ml-2" (click)="open(confirmation, student.id)" ngbTooltip="{{'student.tooltip.delete_student' | translate}}">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-x-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                  <path
                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #confirmation let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Delete Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {{'student.delete.confirmation_dialog' | translate}}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">{{'common.cancel' | translate}}</button>
    <button type="button" class="btn btn-outline-danger" (click)="remove(selectedStudent)">{{'common.delete' | translate}}</button>
  </div>
</ng-template>
