import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService} from '../../../servises/account.service';
import {ProgressBarService} from '../../../../shared/services/progress-bar.service';
import {AlertService} from 'ngx-alerts';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {TranslateService, LangChangeEvent} from "@ngx-translate/core";

@Component({
  selector: 'app-reflective',
  templateUrl: './reflective.component.html',
  styleUrls: ['./reflective.component.css']
})
export class ReflectiveComponent implements OnInit {
  reflectiveForm: FormGroup;
  studentId = null;
  mainSkillId = null;
  locale = '';

  constructor(private route: ActivatedRoute,
              private router: Router,
              private accountService: AccountService,
              public progressBar: ProgressBarService,
              private alertService: AlertService,
              private formBuilder: FormBuilder,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {

      if (this.mainSkillId != params.mainSkillId
        || this.studentId != this.route.parent.snapshot.params.id) {
        this.studentId = this.route.parent.snapshot.params.id;
        this.mainSkillId = params.mainSkillId;
        this.load();
      }
    });
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if (this.locale != event.lang) {
        this.locale = event.lang;
        this.load();
      }
    });

    this.reflectiveForm = this.formBuilder.group({
      // reflections: this.formBuilder.array([this.addReflective()])
      reflections: this.formBuilder.array([])
    });
  }

  load(): void {
    const observer = {
      next: res => {
        this.progressBar.completeLoading();
        console.log(res);
        this.reflectionsArray.clear()
        for (let entry of res.data) {
          this.reflectionsArray.push(this.addReflective());
          // console.log(entry);
        }
        this.reflectionsArray.setValue(res.data);
      },

      error: err => {
        this.alertService.danger(err.error.error[0]);
      }
    };
    this.accountService.getSkillReflective(this.studentId, this.mainSkillId).subscribe(observer);
  }

  addReflective(): any {
    return this.formBuilder.group({
      id: [],
      name: [],
      description: []
    });
  }

  get reflectionsArray(): FormArray {
    return this.reflectiveForm.get('reflections') as FormArray;
  }

  save(): void {
    const observer = {
      next: res => {
        this.progressBar.completeLoading();
        this.alertService.success(res.message);
      },
      error: err => {
        this.alertService.danger(err.error.error[0]);
      }
    };
    this.accountService.saveSkillReflective(this.setRequest()).subscribe(observer);
  }

  private setRequest(): any {
    const data = {
      student_id: +this.studentId,
      skill_id: +this.mainSkillId,
      reflective: []
    };
    for (const entry of this.reflectiveForm.value.reflections) {
      data.reflective.push({id: entry.id, description: entry.description});
    }
    return data;
  }

}
