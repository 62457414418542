<div class="row mt-3">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        {{'account.edit_account.heading' | translate}}
      </div>
      <div class="card-body">
        <form [formGroup]="accountForm" (ngSubmit)="editAccount()">
          <div class="form-group">
            <label for="name">{{'account.edit_account.name.label' | translate}}</label>
            <input type="text"
                   id="name"
                   class="form-control"
                   placeholder="{{'account.edit_account.name.placeholder' | translate}}"
                   aria-label="Name"
                   aria-describedby="basic-addon2"
                   formControlName="name"
                   required>

          </div>
          <div
            *ngIf="accountForm.get('name').invalid && (accountForm.get('name').dirty || accountForm.get('name').touched)"
            class="text-danger">

            <div *ngIf="accountForm.get('name').errors.required">
              {{'account.edit_account.name.error.required' | translate}}
            </div>

          </div>
          <div class=form-group>
            <label for="name">{{'account.edit_account.email.label' | translate}}</label>
            <input type="email"
                   id="email"
                   class="form-control"
                   placeholder="{{'account.edit_account.email.placeholder' | translate}}"
                   aria-label="Email"
                   aria-describedby="basic-addon2"
                   formControlName="email"
                   required>
            <div
              *ngIf="accountForm.get('email').invalid && (accountForm.get('email').dirty || accountForm.get('email').touched)"
              class="text-danger">

              <div *ngIf="accountForm.get('email').errors.required">
                {{'account.edit_account.email.error.required' | translate}}
              </div>
              <div *ngIf="accountForm.get('email').errors.email">
                {{'account.edit_account.email.error.email' | translate}}
              </div>
            </div>
            <div class="text-danger" *ngIf="accountUpdateError && accountUpdateError.email">
              {{accountUpdateError.email[0]}}
            </div>

          </div>

          <button type="submit" class="btn btn-primary" [disabled]="!accountForm.valid">{{'account.edit_account.submit.label' | translate}}</button>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="row mt-3">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        {{'account.change_password.heading' | translate}}
      </div>
      <div class="card-body">
        <form [formGroup]="passwordForm" (ngSubmit)="editPassword()">
          <div class="form-group">
            <label for="current_password">{{'account.change_password.current_password.label' | translate}}</label>
            <input type="password"
                   id="current_password"
                   class="form-control"
                   placeholder="{{'account.change_password.current_password.placeholder' | translate}}"
                   aria-label="Current Password"
                   aria-describedby="basic-addon2"
                   formControlName="current_password"
                   required>
            <div
              *ngIf="passwordForm.get('current_password').invalid && (passwordForm.get('current_password').dirty || passwordForm.get('current_password').touched)"
              class="text-danger">

              <div *ngIf="passwordForm.get('current_password').errors.required">
                {{'account.change_password.current_password.error.required' | translate}}
              </div>

            </div>
            <div class="text-danger" *ngIf="passwordUpdateError && passwordUpdateError.current_password">
              {{passwordUpdateError.current_password[0]}}
            </div>

          </div>

          <div class=form-group>
            <label for="new_password">{{'account.change_password.new_password.label' | translate}}</label>
            <input type="password"
                   id="new_password"
                   class="form-control"
                   placeholder="{{'account.change_password.new_password.placeholder' | translate}}"
                   aria-label="New Password"
                   aria-describedby="basic-addon2"
                   formControlName="new_password"
                   required>
            <div
              *ngIf="passwordForm.get('new_password').invalid && (passwordForm.get('new_password').dirty || passwordForm.get('new_password').touched)"
              class="text-danger">

              <div *ngIf="passwordForm.get('new_password').errors.required">
                {{'account.change_password.new_password.error.required' | translate}}
              </div>
            </div>
            <div class="text-danger" *ngIf="passwordUpdateError && passwordUpdateError.new_password">
              {{passwordUpdateError.new_password[0]}}
            </div>
          </div>
          <div class=form-group>
            <label for="new_password">{{'account.change_password.retype_password.label' | translate}}</label>
            <input type="password"
                   id="re_password"
                   class="form-control"
                   placeholder="{{'account.change_password.retype_password.placeholder' | translate}}"
                   aria-label="Retype Password"
                   aria-describedby="basic-addon2"
                   formControlName="re_password"
                   required>
            <div
              *ngIf="passwordForm.get('re_password').invalid && (passwordForm.get('re_password').dirty || passwordForm.get('re_password').touched)"
              class="text-danger">

              <div *ngIf="passwordForm.get('re_password').errors.required">
                {{'account.change_password.retype_password.error.required' | translate}}
              </div>
              <div *ngIf="passwordForm.get('re_password').errors.mustMatch">Passwords must match</div>
            </div>
          </div>

          <button type="submit" class="btn btn-primary" [disabled]="!passwordForm.valid">{{'account.change_password.submit.label' | translate}}</button>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="row mt-3">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        {{'account.sar_sdr.heading' | translate}}
      </div>
      <div class="card-body">
        <button class="btn btn-outline-primary mr-3" (click)="subjectAccess()">{{'account.sar_sdr.sar.submit.label' | translate}}</button>
        <button class="btn btn-outline-danger" (click)="open(confirmation)">{{'account.sar_sdr.sdr.submit.label' | translate}}</button>
      </div>
    </div>
  </div>
</div>
<ng-template #confirmation let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'account.sar_sdr.sdr.confirmation.title' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {{'account.sar_sdr.sdr.confirmation.message' | translate}}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">{{'account.sar_sdr.sdr.confirmation.cancel' | translate}}</button>
    <button type="button" class="btn btn-outline-danger" (click)="subjectDelete()">{{'account.sar_sdr.sdr.confirmation.delete' | translate}}</button>
  </div>
</ng-template>
