import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService} from '../../../servises/account.service';
import {ProgressBarService} from '../../../../shared/services/progress-bar.service';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {AlertService} from 'ngx-alerts';
import {TranslateService, LangChangeEvent} from "@ngx-translate/core";

@Component({
  selector: 'app-main-skill',
  templateUrl: './main-skill.component.html',
  styleUrls: ['./main-skill.component.css']
})
export class MainSkillComponent implements OnInit {
  skillStatusForm: FormGroup;
  studentId = null;
  skillList = null;
  locale = '';

  constructor(private route: ActivatedRoute,
              private router: Router,
              private accountService: AccountService,
              public progressBar: ProgressBarService,
              private alertService: AlertService,
              private formBuilder: FormBuilder,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.route.parent.params.subscribe((params: any) => {
      if (this.studentId != params.id) {
        this.studentId = params.id;
        this.list(this.studentId);
      }
    });
    this.skillStatusForm = this.formBuilder.group({
      mainSkills: this.formBuilder.array([])
    });
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if (this.locale != event.lang) {
        this.locale = event.lang;
        this.list(this.studentId);
      }
    });
  }


  list(studentId): void {
    this.progressBar.startLoading();
    const loginObserver = {
      next: res => {
        this.progressBar.completeLoading();
        this.skillList = res.data;
        this.mainSkillArray.clear()
        for (let entry of res.data) {
          this.mainSkillArray.push(this.addMainSkill());
        }
        this.mainSkillArray.setValue(res.data);
      },
      error: err => {
        this.progressBar.completeLoading();
      }
    };
    this.accountService.mainSkillList(studentId).subscribe(loginObserver);
  }


  markAsComplete(item): void {
    this.progressBar.startLoading();
    const observer = {
      next: res => {
        this.progressBar.completeLoading();
        this.alertService.success(res.message);
      },
      error: err => {
        this.progressBar.completeLoading();
      }
    };
    const data = this.setSkillCompleteData(item.id, item.completed);
    this.accountService.skillMarkAsComplete(data).subscribe(observer);
  }

  addMainSkill(): any {
    return this.formBuilder.group({
      id: [],
      name: [],
      completed: [],
      progress: []
    });
  }

  get mainSkillArray(): FormArray {
    return this.skillStatusForm.get('mainSkills') as FormArray;
  }

  private setSkillCompleteData(skillId, competed): any {
    return {
      student_id: this.studentId,
      skill_id: skillId,
      completed: competed ? 1 : 0,
    };
  }
}
