import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MustMatch} from '../../../shared/helpers/must-match.validator';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../../../shared/services/auth.service';
import {ProgressBarService} from '../../../shared/services/progress-bar.service';
import {AlertService} from 'ngx-alerts';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  signupForm: FormGroup;
  errors = null;
  constructor(private authService: AuthService,
              public progressBar: ProgressBarService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.signupForm = new FormGroup({
      name: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, Validators.required),
      password_confirmation: new FormControl(null, Validators.required)
    }, {
      validators: MustMatch('password', 'password_confirmation')
    });
  }

  register(): void {
    this.progressBar.startLoading();
    const data = {
      name: this.signupForm.value.name,
      email: this.signupForm.value.email,
      password: this.signupForm.value.password,
      password_confirmation: this.signupForm.value.password_confirmation
    };
    const registerObserver = {
      next: x => {
        this.progressBar.completeLoading();
        this.alertService.success('Registration successful please login');
        this.errors = null;
        this.signupForm.reset();
      },
      error: err => {
        this.progressBar.completeLoading();
        console.log(err);
        this.errors = err.error.error;
      }
    };
    this.authService.register(data).subscribe(registerObserver);
  }

}
