import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ProgressBarService} from '../../../../shared/services/progress-bar.service';
import {AlertService} from 'ngx-alerts';
import {AccountService} from '../../../servises/account.service';
import {MustMatch} from '../../../../shared/helpers/must-match.validator';
import {AuthService} from '../../../../shared/services/auth.service';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  accountForm: FormGroup;
  passwordForm: FormGroup;
  userData = null;
  accountUpdateError = null;
  passwordUpdateError = null;

  constructor(public progressBar: ProgressBarService,
              private alertService: AlertService,
              private accountService: AccountService,
              private authService: AuthService,
              private router: Router,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.accountForm = new FormGroup({
      name: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email])
    });
    this.passwordForm = new FormGroup({
      current_password: new FormControl(null, Validators.required),
      new_password: new FormControl(null, [Validators.required]),
      re_password: new FormControl(null, [Validators.required])
    }, {
      validators: MustMatch('new_password', 're_password')
    });
    this.loadUser();
  }

  editAccount(): void {
    this.progressBar.startLoading();
    const observer = {
      next: res => {
        this.progressBar.completeLoading();
        this.accountUpdateError = null;
        this.alertService.success(res.message[0]);
      },
      error: err => {
        this.progressBar.completeLoading();
        this.accountUpdateError = err.error.error;
      }
    };
    this.accountService.editAccount(this.accountForm.value).subscribe(observer);
  }

  loadUser(): void {
    this.progressBar.startLoading();
    const observer = {
      next: res => {
        this.progressBar.completeLoading();

        this.userData = res.data;
        console.log(res);
        this.accountForm.setValue({name: res.name, email: res.email});
      },
      error: err => {
        this.progressBar.completeLoading();
        console.log(err);
      }
    };
    this.accountService.getCurrentUser().subscribe(observer);
  }

  editPassword(): void {
    this.progressBar.startLoading();
    const observer = {
      next: res => {
        this.progressBar.completeLoading();
        this.passwordUpdateError = null;
        this.alertService.success(res.message);
        this.authService.logout();
        this.router.navigate(['/']);
      },
      error: err => {
        this.progressBar.completeLoading();
        this.passwordUpdateError = err.error.error;
      }
    };
    const data = {
      new_password: this.passwordForm.value.new_password,
      current_password: this.passwordForm.value.current_password
    };
    this.accountService.editPassword(data).subscribe(observer);
    this.passwordForm.reset();
  }

  subjectAccess(): void {
    this.progressBar.startLoading();
    const observer = {
      next: res => {
        this.progressBar.completeLoading();
        this.alertService.success(res.message);
      },
      error: err => {
        this.progressBar.completeLoading();
        console.log(err);
      }
    };
    this.accountService.subjectAccess().subscribe(observer);
  }

  subjectDelete(): void {
    this.progressBar.startLoading();
    const observer = {
      next: res => {
        this.progressBar.completeLoading();
        this.alertService.success(res.message);
        this.authService.clearBrowserStorage();
        this.router.navigate(['/']);
      },
      error: err => {
        this.progressBar.completeLoading();
        console.log(err);
      }
    };
    this.accountService.subjectDelete().subscribe(observer);
    this.modalService.dismissAll();
  }

  open(confirmation) {
    this.modalService.open(confirmation, {ariaLabelledBy: 'modal-basic-title'});
  }
}
