import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService} from '../../../servises/account.service';
import {ProgressBarService} from '../../../../shared/services/progress-bar.service';
import {AlertService} from 'ngx-alerts';
import {AuthService} from '../../../../shared/services/auth.service';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css']
})
export class CommentComponent implements OnInit {
  commentForm: FormGroup;
  studentId = null;
  mainSkillId = null;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private accountService: AccountService,
              public progressBar: ProgressBarService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.commentForm = new FormGroup({
      comment: new FormControl(null, Validators.required)
    });
    this.route.params.subscribe((params :any) => {
      this.studentId = this.route.parent.snapshot.params.id;
      this.mainSkillId = params.mainSkillId;
      this.load();
    });
  }

  save(): void {
    if (!this.accountService.isLoggedIn()){
        return null;
    }
    const data = {
      student_id: this.studentId,
      skill_id: this.mainSkillId,
      comment: this.commentForm.value.comment
    };
    const observer = {
      next: res => {
        this.progressBar.completeLoading();
        this.alertService.success(res.message);
      },

      error: err => {
        this.progressBar.completeLoading();
        this.alertService.danger(err.error.error[0]);
      }
    };
    this.accountService.saveSkillComment(data).subscribe(observer);
  }

  load(): void {
    this.progressBar.startLoading();
    const observer = {
      next: res => {
        this.progressBar.completeLoading();
        if (res.status) {
          this.commentForm.controls.comment.setValue(res.data.comment);
        }
      },

      error: err => {
        this.alertService.danger(err.error.error[0]);
      }
    };
    this.accountService.getSkillComment(this.studentId, this.mainSkillId).subscribe(observer);
  }

}
