import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService} from '../../../servises/account.service';
import {ProgressBarService} from '../../../../shared/services/progress-bar.service';
import {AlertService} from 'ngx-alerts';
import {TranslateService, LangChangeEvent} from "@ngx-translate/core";

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.css']
})
export class SessionComponent implements OnInit {
  sessionForm: FormGroup;
  studentId = null;
  student = null;
  studentPath = null;
  subSkillId = null;
  addButtonDisableStatus = true;
  mainSkillPath = null;
  subSkill = null;
  mainSkill = null;
  locale = '';

  constructor(private route: ActivatedRoute,
              private router: Router,
              private accountService: AccountService,
              public progressBar: ProgressBarService,
              private alertService: AlertService,
              private formBuilder: FormBuilder,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      this.studentId = this.route.parent.snapshot.params.id;
      this.subSkillId = params.subSkillId;
      this.load();
      this.subSkillDetails();
    });
    this.sessionForm = this.formBuilder.group({
      // reflections: this.formBuilder.array([this.addSession()])
      sessions: this.formBuilder.array([])
    });
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if (this.locale != event.lang) {
        this.locale = event.lang;
        this.load();
        this.subSkillDetails();
      }
    });
  }

  load(): void {
    this.progressBar.startLoading();
    const observer = {
      next: res => {
        this.progressBar.completeLoading();
        let sessions = res.data.sessions;
        this.student = res.data.student;
        this.studentPath = `/student/${this.student.id}/skill`;
        this.sessionArray.clear();
        if (sessions.length === 0) {
          this.sessionArray.push(this.addSession());
        } else {
          for (let entry of sessions) {
            this.sessionArray.push(this.addSession());
          }
          this.sessionArray.setValue(sessions);
          this.addButtonDisableStatus = false;
        }
      },

      error: err => {
        this.alertService.danger(err.error.error[0]);
        this.progressBar.completeLoading();
        this.router.navigate(['/account']);
      }
    };
    this.accountService.getSessionList(this.studentId, this.subSkillId).subscribe(observer);
  }

  get sessionArray(): FormArray {
    return this.sessionForm.get('sessions') as FormArray;
  }

  private addSession(): FormGroup {
    return this.formBuilder.group({
      id: [],
      session_no: [],
      skill_completed: [],
      student_id: [],
      sub_skill_id: [],
      created_at: [],
    });
  }

  save(group, state): void {
    console.log(group);
    this.addButtonDisableStatus = false;
    this.progressBar.startLoading();
    const data = {
      student_id: +this.studentId,
      sub_skill_id: +this.subSkillId,
      session_no: group.session_no,
      completed: state,
      // completed: group.skill_completed ? 1 : 0,
    };
    const observer = {
      next: res => {
        this.progressBar.completeLoading();
        this.alertService.success(res.message);
        this.sessionArray.clear();
        this.load();
      },
      error: err => {
        this.alertService.danger(err.error.error[0]);
        this.progressBar.completeLoading();
      }
    };
    this.accountService.saveSession(data).subscribe(observer);
  }

  add(): void {
    this.addButtonDisableStatus = true;
    this.sessionArray.push(this.addSession());
  }

  subSkillDetails(): void {
    this.progressBar.startLoading();
    const observer = {
      next: res => {
        this.progressBar.completeLoading();
        this.subSkill = res.data.sub_skill;
        this.mainSkill = res.data.main_skill
        this.mainSkillPath = `/student/${this.studentId}/sub-skill/${this.mainSkill.id}`;
      },
      error: err => {
        this.progressBar.completeLoading();
        this.alertService.danger(err.error.error);
        this.router.navigate(['/account']);
      }
    };
    this.accountService.getSubSkillById(this.subSkillId).subscribe(observer);
  }
}
