<section id="auth">
  <div>
    <img src="/assets/img/hero.png" alt="Hero!"  />
  </div>
  <main>
    <div>
      <router-outlet></router-outlet>
    </div>
  </main>
</section>

