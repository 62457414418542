import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FrontComponent } from './layouts/front/front.component';
import { HeaderComponent } from './components/header/header.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { NgProgressModule } from 'ngx-progressbar';
import {AlertModule} from 'ngx-alerts';
import { BrowserModule } from '@angular/platform-browser';
import { NotFoundComponentComponent } from './components/not-found-component/not-found-component.component';
import {NgbTooltipModule,NgbCollapseModule} from '@ng-bootstrap/ng-bootstrap';
import { LanguageComponent } from './components/language/language.component';
import {TranslateSharedModule} from "../translate-shared.module";
@NgModule({
  declarations: [FrontComponent, HeaderComponent, NotFoundComponentComponent, LanguageComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    BrowserModule,
    RouterModule,
    HttpClientModule,
    NgProgressModule,
    NgbTooltipModule,
    NgbCollapseModule,
    BsDropdownModule.forRoot(),
    AlertModule.forRoot({maxMessages: 5, timeout: 5000, positionX: 'right', positionY: 'top'}),
    TranslateSharedModule
  ],
  exports: [
    FrontComponent,
    LanguageComponent
  ]
})
export class SharedModule { }
