<div class="card">
  <div class="card-header">
    {{'sub_skill.reflective.heading' | translate}}
  </div>
  <div class="card-body">
    <form action="" [formGroup]="reflectiveForm" (ngSubmit)="save()">
      <div formArrayName="reflections">
        <div *ngFor="let group of reflectionsArray.controls; let i = index;" [formGroupName]="i">
          <label for="{{i}}">{{group.controls.name.value}}</label>
          <textarea formControlName="description" class="form-control" id="{{i}}"></textarea>
        </div>
      </div>
      <button type="submit" class="btn btn-success mt-3">{{'sub_skill.reflective.submit.label' | translate}}</button>
    </form>
  </div>
</div>
