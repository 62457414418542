<span class="title-form">
  <h2>{{'login.heading' | translate}}</h2>
  <p>{{'login.welcome_para' | translate}}</p>
</span>

<form [formGroup]="signInForm" (ngSubmit)="login()">
  <label class="label-animation">
    <input type="email" placeholder=" " formControlName="email" required email >
    <p>{{'login.input.email.label' | translate}}</p>
    <i class="far fa-envelope"></i>
  </label>
  <div *ngIf="signInForm.get('email').invalid && (signInForm.get('email').dirty || signInForm.get('email').touched)" class="no-valid">
    <div *ngIf="signInForm.get('email').errors.required">{{'login.input.email.error.required' | translate}}</div>
    <div *ngIf="signInForm.get('email').errors.email">{{'login.input.email.error.email' | translate}}</div>
  </div>

  <label class="label-animation">
    <input type="password" placeholder=" " formControlName="password" id="password" required >
    <p>Password</p>
    <span (click)="passVisible('#password')">
      <i class="far fa-eye"></i>
      <i class="far fa-eye-slash"></i>
    </span>
  </label>
  <div *ngIf="signInForm.get('password').invalid && (signInForm.get('password').dirty || signInForm.get('password').touched)" class="no-valid">
    <div *ngIf="signInForm.get('password').errors.required">{{'login.input.password.error.required' | translate}}</div>
  </div>

  <button class="full-btn" type="submit" [disabled]="!signInForm.valid">{{'login.action.submit.label' | translate}}</button>

  <div id="actions-form">
    <label class="chek-font">
      <input type="checkbox" class="form-check-input" formControlName="keepMeLoggedIn"  name="keepMeLoggedIn" id="keepMeLoggedIn">
      <div><i class="far fa-square"></i><i class="fas fa-check-square"></i></div>
      <p>{{'login.input.keep_sign_in.label' | translate}}</p>
    </label>

    <a class="link-line" routerLink="/reset-password">{{'login.forgot_password.label' | translate}}</a>
  </div>
</form>

