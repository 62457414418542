import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AuthRoutingModule} from './auth-routing.module';
import {LoginComponent} from './components/login/login.component';
import {RegisterComponent} from './components/register/register.component';
import {RestPasswordComponent} from './components/rest-password/rest-password.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CookieService} from 'ngx-cookie-service';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { AuthComponent } from './auth.component';
import {TranslateSharedModule} from "../translate-shared.module";


@NgModule({
  declarations: [LoginComponent, RegisterComponent, RestPasswordComponent, AuthComponent],
  providers: [ CookieService ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    TranslateSharedModule
  ],
  exports: [
    LoginComponent,
    RegisterComponent,
    RestPasswordComponent,
  ]
})
export class AuthModule {
}
